import {useContext, useEffect, useState, useRef} from "react";
import {NavLink} from "react-router-dom";
import {useStripe, useElements} from '@stripe/react-stripe-js';
import {AxiosError} from "axios";
import {Stripe, StripeElements} from "@stripe/stripe-js";
import styled from "styled-components";

// Assets
import {IckonicLogo} from "../../Assets/Images/Logos/Logos";
import {IconEye, IconEyeOff, IconQuestionFilled} from "../../Assets/Icons/Icons";

// Models
import {IRegisterDTO} from "../../Models/DTOs/IRegisterDTO.";
import {PlanType} from "../../Models/Enums/PlanType";
import IPlanDTO from "../../Models/DTOs/IPlanDTO";
import {IProgressStep} from "../../Models/IProgressStep";

// Api
import {Register, Login, GetUserDetails} from "../../Api/Account";
import SubmitEmailFreeView from "../../Api/EmailFreeView";
import {AddPaymentMethod, CreateSubscription} from "../../Api/Stripe";

// Components
import ButtonPillUppercase from "../../Components/UI/Buttons/ButtonPillUppercase";
import InputField from "../../Components/UI/Inputs/InputField";
import InputCheckbox from "../../Components/UI/Inputs/InputCheckbox";
import InputsStripe from "../../Components/UI/Inputs/InputsStripe";
import PlansSelector from "../../Components/Layouts/PlansSelector";
import Tooltip from "../../Components/ToolTip";
import PopUpBasic from "../../Components/Popups/PopUpBasic";
import ProgressPanel from "../../Components/Popups/ProgressPanel";

// Global state
import AuthContext from "../../Store/auth-context";

// Utility functions
import {emailValidation, nameValidation, passwordValidation, setFormError} from "../../Helpers/Utility";
import {RoutePaths} from "../../Constants/RoutePaths";
import {Colours} from "../../Constants/Colours";

interface FormState {
    FirstName: string;
    LastName: string;
    Email: string;
    Password: string;
    ConfirmPassword: string;
    CreditCard: string;
    AllowCommercialEmails: boolean;
    TermsOfServiceAccept: boolean;
}

const initialFormState: FormState = {
    FirstName: "",
    LastName: "",
    Email: "",
    Password: "",
    ConfirmPassword: "",
    CreditCard: "",
    AllowCommercialEmails: true,
    TermsOfServiceAccept: false,
};

type FormKey = keyof FormState;

interface ErrorState {
    FirstName: string;
    LastName: string;
    Email: string;
    Password: string;
    Plans: string;
    ConfirmPassword: string;
    CreditCard: string;
    AllowCommercialEmails: string;
    TermsOfServiceAccept: string;
}

const initialErrorState: ErrorState = {
    FirstName: "valid",
    LastName: "valid",
    Email: "valid",
    Password: "valid",
    Plans: "valid",
    ConfirmPassword: "valid",
    CreditCard: "valid",
    AllowCommercialEmails: "valid",
    TermsOfServiceAccept: "valid",
};

const SignUpForm = styled.div`
    padding: 0 0 1rem 0;
    background: linear-gradient(180deg, rgba(14, 14, 14, 1) 0%, rgb(7, 7, 7) 100%);
    min-height: 100vh;

    * {
        color: white;
        box-sizing: border-box;
    }

    a {
        color: #b760b4;

        &:hover,
        &:visited,
        &:active {
            color: #b760b4;
        }
    }

    .page__header {
        height: calc(55rem / 16);
        width: 100%;
        box-shadow: 0 0 calc(3rem / 16) calc(2rem / 16) rgba(0, 0, 0, 0.3);
        background: #0e0e0e;

        .ickonic-logo--primary {
            margin: 0 auto;
            display: block;
            width: 100%;
            height: 100%;
            padding: calc(12rem / 16);

            * {
                fill: white;
            }
        }
    }

    .page__inner {
        &.is-loading {
            filter: blur(calc(10rem / 16));
        }
    }

    .form--sign-up {
        padding: 1rem;
        width: 100%;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        max-width: calc(550rem / 16);

        h1 {
            text-align: left;
            font-family: "Manrope", Helvetica sans-serif;
            font-weight: 900;
            font-size: calc(24rem / 16);
            margin: 0 0 calc(8rem/16) 0;
        }

        > h2 {
            font-size: calc(20rem / 16);
            font-weight: 400;
            margin: 0 0 calc(8rem/16) 0;
            line-height: 1.3;
        }

        .sign-up__login-switch {
            margin: 1rem 0 calc(8rem/16) 0;
            font-size: calc(12rem/16);
            a {
                text-decoration: none;
                color: ${Colours.IckonicPinkHighlight};
                &:hover,
                &:focus,
                &:active {
                    text-decoration: underline;
                }
            }
        }

        .form__credit-card-number {
            margin-bottom: 2rem;
        }

        .form__checkbox {
            line-height: 1.4;
            margin: 0 0 1.5rem 0;
        }

        .form__pw-wrapper {
            width: 100%;
            position: relative;
            input {
                padding: calc(26rem/16) calc(60rem/16) calc(8rem/16) calc(12rem/16);
            }

            svg {
                position: absolute;
                top: calc(15rem/16);
                right: calc(15rem/16);
                height: calc(30rem/16);
                width: calc(30rem/16);
                z-index: 1;
                cursor: pointer;
                * {
                    fill: ${Colours.LighterGrey};
                }
            }
        }

        .form__popup-trigger {
            cursor: pointer;
            display: block;
            width: 100%;
            text-align: center;
            color: #b760b4;
            font-size: calc(14rem / 16);
            margin: calc(8rem / 16) 0 0 0;
        }

        h4 {
            width: 100%;
            text-align: center;
            position: relative;
            font-weight: normal;

            svg {
                height: calc(15rem / 16);
                width: calc(15rem / 16);
                position: relative;
                top: calc(2rem / 16);

                * {
                    fill: white;
                }
            }

            &:first-of-type {
                margin-top: 2rem;
            }

            &:before {
                height: calc(3rem / 16);
                width: 100%;
                content: '';
                position: absolute;
                background: #202020;
                top: calc(50% - (1.5rem / 16));
                left: 0;
            }

            span {
                position: relative;
                z-index: 1;
                padding: 0 calc(10rem / 16);
                background: #0e0e0e;
            }
        }

        .form__input-text {
            margin: 0 0 1rem 0;
        }

        button {
            margin: 1rem 0 0 0;
            width: 100%;
            display: block;
            background: linear-gradient(90deg, #b760b4 0%, rgb(232, 187, 39) 100%);
            box-shadow: 0 calc(3rem / 16) calc(2rem / 16) rgba(0, 0, 0, 0.2);
            text-shadow: calc(2rem / 16) calc(2rem / 16) calc(2rem / 16) rgba(0, 0, 0, 0.3);
            font-size: calc(14rem / 16);
            letter-spacing: calc(1rem / 16);

            &:hover,
            &:focus,
            &:active {
                background: linear-gradient(90deg, #b760b4 0%, rgb(232, 187, 39) 100%);
            }
        }
    }

    @media screen and (min-width: 20em) {
        .form--sign-up {
            padding: 2rem;

            h1 {
                margin: 0rem 0 .5rem 0;
            }
        }
    }

    @media screen and (min-width: 25em) {
        .form--sign-up {
            h1 {
                font-size: calc(32rem / 16);
            }
        }
    }

    @media screen and (min-width: 36.5em) {
        .form--sign-up {
            flex-wrap: wrap;
            justify-content: space-between;

            .form__name-first,
            .form__name-last {
                width: calc(50% - (8rem / 16)) !important;
            }

            h1 {
                margin: 0.5rem 0;
            }
        }
    }
`;

const SignUp = () => {
    const
        /**
         * Local functions / misc vars
         */
        abortController = new AbortController(),
        validationFunctions: { [key: string]: (value: string) => string } = {
            Email: emailValidation,
            Password: passwordValidation,
            FirstName: nameValidation
            // Add other validation functions here...
        },

        /**
         * Global state
         */
        userAuthenticationContext = useContext(AuthContext),

        /**
         * Hooks
         */
        stripe = useStripe(),
        elements = useElements(),

        /**
         * Local state
         */
        [formData, setFormData] = useState<FormState>(initialFormState),
        [zipCode, setZipCode] = useState(""),
        [errors, setErrors] = useState<ErrorState>(initialErrorState),
        [selectedPlan, setSelectedPlan] = useState<IPlanDTO | null>(null),
        [isCardComplete, setCardComplete] = useState(false),
        [plansPopUpActive, setPlansPopUpActive] = useState(false),
        [isLoading, setIsLoading] = useState(false),
        [progressPopUpActive, setProgressPopUpActive] = useState(false),
        [showPWValue, setShowPWValue] = useState<boolean>(false),

        // Progress message and steps are used to create a
        // progress bar experience that pops up on form submit.
        // you will see these steps being amended throughout the
        // registration process in the functions below.
        [progressMessage, setProgressMessage] = useState<string>(""),
        [progressSteps, setProgressSteps] = useState<IProgressStep[]>([
            { percent: 25, status: 'pending' },
            { percent: 50, status: 'pending' },
            { percent: 75, status: 'pending' },
            { percent: 100, status: 'pending' }
        ]),

        /**
         * Refs
         */
        emailInputRef = useRef<HTMLInputElement>(null),

        /**
         * Handlers
         */
            // Handles input value state
        handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            const { name, value, type, checked } = e.target;
            setFormData((prevData) => ({
                ...prevData,
                [name]: type === "checkbox" ? checked : value
            }));
        },

        handlePlanSelect = (plan: IPlanDTO) => {
            setSelectedPlan(plan);
            setFormError(setErrors, 'Plans', "valid");
        },

        // First step in registration process, creates the user account
        registerUser = async (dto: IRegisterDTO): Promise<{ success: boolean; data?: any; error?: string }> => {
            const result = await Register(dto, abortController);

            if (result instanceof AxiosError || !result.length) {
                setProgressMessage("Registration failed");
                setProgressSteps(prevSteps => [
                    { percent: 25, status: 'error' },
                    prevSteps[1],
                    prevSteps[2],
                    prevSteps[3]
                ]);
                return { success: false, error: "Registration failed" };
            }

            setProgressSteps(prevSteps => [
                { percent: 25, status: 'success' },
                prevSteps[1],
                prevSteps[2],
                prevSteps[3]
            ]);

            return { success: true, data: result };
        },

        // Second step, logs the user in and retrieves their token,
        // sends token to GetUserDetails to retrieve the AspNetUserId
        loginAndFetchUserData = async (email: string, password: string): Promise<{ success: boolean; data?: string; error?: string }> => {
            setProgressMessage("Embedding Divine Spark");
            try {
                const loginResponse = await Login(email, password, abortController)
                const tokenData = loginResponse as string;

                if (!tokenData) {
                    throw new Error("Login failed. Please check your credentials.");
                }

                setProgressSteps(prevSteps => [
                    prevSteps[0],
                    { percent: 50, status: 'success' },
                    prevSteps[2],
                    prevSteps[3]
                ]);

                return { success: true, data: tokenData };
            } catch (error) {
                const errorMessage = error instanceof Error ? error.message : "An unexpected error occurred";
                setProgressMessage(errorMessage);
                setProgressSteps(prevSteps => [
                    prevSteps[0],
                    { percent: 50, status: 'error' },
                    prevSteps[2],
                    prevSteps[3]
                ]);
                return { success: false, error: errorMessage };
            }
        },

        getUserDetails = async (token: string): Promise<{ success: boolean; data?: string; error?: string }> => {
            // Make sure token is stored in localStorage
            userAuthenticationContext.Update(token);

            try {
                const userDetails = await GetUserDetails(token, new AbortController());

                if (userDetails instanceof Error || !userDetails) {
                    throw new Error("Failed to fetch user details.");
                }

                return { success: true, data: userDetails.AspNetUserId };
            } catch (error) {
                const errorMessage = error instanceof Error ? error.message : "An unexpected error occurred";
                setProgressMessage(errorMessage);
                setProgressSteps(prevSteps => [
                    prevSteps[0],
                    { percent: 50, status: 'error' },
                    prevSteps[2],
                    prevSteps[3]
                ]);
                return { success: false, error: errorMessage };
            }
        },

        // Third step (part 1), takes card information and creates a payment method in stripe
        createPaymentMethod = async (stripe: Stripe, elements: StripeElements): Promise<{ success: boolean; data?: any; error?: string }> => {
            setProgressMessage("Reserving Passage");
            const { paymentMethod, error } = await stripe.createPaymentMethod({
                type: 'card',
                card: elements.getElement('cardNumber')!,
                billing_details: {
                    address: {
                        postal_code: zipCode,
                    },
                }
            });

            console.log(paymentMethod);

            if (error) {
                setProgressMessage("Failed confirming payment method");
                setProgressSteps(prevSteps => [
                    prevSteps[0],
                    prevSteps[1],
                    { percent: 75, status: 'error' },
                    prevSteps[3]
                ]);
                return { success: false, error: `Error creating payment method: ${error.message}` };
            }

            return { success: true, data: paymentMethod! };
        },

        // Third step (part 2). Registers payment method with the Ickonic API
        addPaymentMethodToBackend = async (aspNetUserId: string, paymentMethodId: string): Promise<{ success: boolean; error?: string }> => {
            try {
                const response = await AddPaymentMethod(aspNetUserId, paymentMethodId, abortController);
                if (response instanceof AxiosError) {
                    throw new Error("Failed to add payment method");
                }

                setProgressSteps(prevSteps => [
                    prevSteps[0],
                    prevSteps[1],
                    { percent: 75, status: 'success' },
                    prevSteps[3]
                ]);

                return { success: true };
            } catch (error) {
                const errorMessage = error instanceof Error ? error.message : "An unexpected error occurred";
                setProgressMessage(errorMessage);
                setProgressSteps(prevSteps => [
                    prevSteps[0],
                    prevSteps[1],
                    { percent: 75, status: 'error' },
                    prevSteps[3]
                ]);
                return { success: false, error: errorMessage };
            }
        },

        // Step five, starts the subscription based on the plan the
        // user selected (using the created payment method in step 4).
        createSubscription = async (aspNetUserId: string, paymentMethodId: string): Promise<{ success: boolean; error?: string }> => {
            setProgressMessage("Entering the matrix");

            try {
                const response = await CreateSubscription(
                    formData.Email,
                    selectedPlan!.PlanId,
                    aspNetUserId,
                    selectedPlan!.Type as PlanType,
                    paymentMethodId
                );

                // If we reach here, it means the subscription was created successfully
                setProgressSteps(prevSteps => [
                    prevSteps[0],
                    prevSteps[1],
                    prevSteps[2],
                    { percent: 100, status: 'success' }
                ]);

                return { success: true };
            } catch (error) {

                // This will catch both AxiosError and any other errors
                let errorMessage = "Failed to create subscription";

                setProgressMessage("Failed to create subscription");
                setProgressSteps(prevSteps => [
                    prevSteps[0],
                    prevSteps[1],
                    prevSteps[2],
                    { percent: 100, status: 'error' }
                ]);

                return { success: false, error: errorMessage };
            }
        },

        // Runs on form onSubmit
        // - Registers new user account
        // - Logs user in and retrieves AspNetUserId
        // - Creates payment method with provided card info
        // - Saves payment method in Ickonic DB for future use
        // - Creates subscription using selected plan
        // - Displays "Success" message if all steps succeed
        handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();

            const dto: IRegisterDTO = {
                Id: "unique-id", // Replace this with your logic for generating or getting the ID
                Firstname: formData.FirstName,
                Lastname: formData.LastName,
                Email: formData.Email,
                Password: formData.Password,
                ConfirmPassword: formData.Password,
                AllowCommercialEmails: formData.AllowCommercialEmails
            };

            if (selectedPlan === null || !isCardComplete) {
                setFormError(setErrors, 'Plans', selectedPlan === null ? "Please select a plan" : "valid");
                setFormError(setErrors, 'CreditCard', !isCardComplete ? "Credit card information appears to be invalid" : "valid");
                return;
            }

            // Launch the progress panel
            setIsLoading(true);
            setProgressPopUpActive(true);
            setProgressMessage("Constructing Hologram");

            try {
                const registrationResult = await registerUser(dto);
                if (!registrationResult.success) {
                    return;
                }

                const loginResult = await loginAndFetchUserData(formData.Email, formData.Password);
                if (!loginResult.success) {
                    return;
                }

                const getUserDetailsResult = await getUserDetails(loginResult.data ? loginResult.data : "");
                if (!getUserDetailsResult.success) {
                    return;
                }

                const aspNetUserId = getUserDetailsResult.data ? getUserDetailsResult.data : "";

                if (!stripe || !elements) {
                    setProgressMessage("Payment processor not initialized");
                    setProgressSteps(prevSteps => [
                        prevSteps[0],
                        prevSteps[1],
                        { percent: 75, status: 'error' },
                        prevSteps[3]
                    ]);
                    return;
                }

                const paymentMethodResult = await createPaymentMethod(stripe, elements);
                if (!paymentMethodResult.success) {
                    return;
                }

                const addPaymentResult = await addPaymentMethodToBackend(aspNetUserId, paymentMethodResult.data!.id);
                if (!addPaymentResult.success) {
                    return;
                }

                const subscriptionResult = await createSubscription(aspNetUserId, paymentMethodResult.data!.id);
                if (!subscriptionResult.success) {
                    return;
                }

                setProgressMessage('Success!');
                // Redirect to success page or dashboard

            } catch (error) {
                console.error("Unexpected error:", error);
                // Handle any unexpected errors that weren't caught by the individual functions
            } finally {}
        },

        handleBlur = (inputName: FormKey) => {
            const validate = validationFunctions[inputName];

            if (validate) {
                const validationResult = validate(formData[inputName] as string);
                setFormError(setErrors, inputName, validationResult);

                if (inputName === 'Email' && validationResult === 'valid') {
                    // Send to marketing Api if it passes
                    SubmitEmailFreeView(abortController, formData.Email);
                }
            }
        },

        handleCloseProgressPopUp = () => {
            setProgressPopUpActive(false);
            setIsLoading(false);
        };

    useEffect(() => {

        /**
         * Focus email input by default
         */
        if (emailInputRef.current) {
            emailInputRef.current.focus();
        }

        return () => {
            abortController.abort();
        };
    }, []);

    return (
        <SignUpForm className={`page page--sign-up page--no-nav`}>
            {/*<span onClick={() => testProgressPopUp()}>Test progress popup</span>*/}
            <div className={`page__inner ${isLoading ? 'is-loading' : 'is-not-loading'}`}>
                <div className="page__header">
                    <NavLink to='/'>
                        {IckonicLogo()}
                    </NavLink>
                </div>

                <form className="form--sign-up" onSubmit={handleSubmit}>
                    <h1>
                        Create Your Account
                    </h1>

                    <h2>
                        <span>Expand your awareness. </span>
                        <span>Free your perception.</span>
                    </h2>

                   <p className="sign-up__login-switch">
                        Already have an account? <NavLink to={RoutePaths.Login()}>Login</NavLink>
                   </p>

                    {/** Email Address **/}
                    <InputField
                        type="email"
                        name="Email"
                        value={formData.Email}
                        label="Email"
                        onChange={handleChange}
                        onFocus={() => {
                        }}
                        onBlur={() => handleBlur("Email")}
                        hasError={errors.Email !== "valid"}
                        required
                        ref={emailInputRef}
                        errorMessage={errors.Email}
                    />

                    {/** First Name **/}
                    <InputField
                        type="text"
                        name="FirstName"
                        value={formData.FirstName}
                        label="First Name"
                        onChange={handleChange}
                        className="form__name-first"
                        onFocus={() => {
                        }}
                        onBlur={() => handleBlur("FirstName")}
                        required
                        hasError={errors.FirstName !== "valid"}
                        errorMessage={errors.FirstName}
                    />

                    {/** Last Name **/}
                    <InputField
                        type="text"
                        name="LastName"
                        value={formData.LastName}
                        label="Last Name"
                        onChange={handleChange}
                        className="form__name-last"
                        onFocus={() => {
                        }}
                        onBlur={() => handleBlur("LastName")}
                        hasError={errors.LastName !== "valid"}
                        errorMessage={errors.LastName}
                    />

                    {/** Password **/}
                    <div className="form__pw-wrapper">
                        <InputField
                            type={showPWValue ? 'text' : 'password'}
                            name="Password"
                            value={formData.Password}
                            label="Password"
                            onChange={handleChange}
                            onFocus={() => {}}
                            onBlur={() => handleBlur("Password")}
                            required
                            hasError={errors.Password !== "valid"}
                            errorMessage={errors.Password}
                        />

                        <div onClick={() => setShowPWValue(!showPWValue)}>{showPWValue ? IconEyeOff() : IconEye()}</div>
                    </div>


                    {/** Tier / Plan select **/}
                    <h4 style={{margin: '1rem 0 2rem 0'}}><span>Select a plan</span></h4>
                    <PlansSelector
                        onSelectPlan={(plan) => handlePlanSelect(plan)}
                        parentSelectedPlan={selectedPlan}
                        hasError={errors.Plans !== "valid"}
                        errorMessage={errors.Plans}
                        featuresStart={2}
                        featuresEnd={5}
                    />

                    <span className="form__popup-trigger" onClick={() => setPlansPopUpActive(true)}>
                    Expand all features
                </span>

                    <PopUpBasic
                        isOpen={plansPopUpActive}
                        onClose={() => setPlansPopUpActive(false)}
                        padding="30px 20px 20px 20px"
                        borderRadius="16px"
                    >
                        <PlansSelector
                            onSelectPlan={(plan) => {
                                handlePlanSelect(plan);
                                setPlansPopUpActive(false);
                            }}
                            parentSelectedPlan={selectedPlan}
                            hasError={errors.Plans !== "valid"}
                            errorMessage={errors.Plans}
                            featuresStart={0}
                            featuresEnd={10}
                        />
                    </PopUpBasic>

                    {/** "Don't panic" payment information tooltip **/}
                    <h4 style={{margin: '2rem 0 1rem 0'}}>
                        <span>
                            <Tooltip content="You can still cancel the auto-renewal even after giving your card details, so don't panic! We just need your card details to verify you are human. Just keep in mind to cancel at least two days before the subscription renewal date.">
                                Payment Information {IconQuestionFilled()}
                            </Tooltip>
                        </span>
                    </h4>

                    {/** Stripe Credit Card Form **/}
                    <InputsStripe
                        name="CreditCard"
                        value={formData.CreditCard}
                        label="Credit Card"
                        onFocus={() => {}}
                        onBlur={() => handleBlur("CreditCard")}
                        hasError={errors.CreditCard !== "valid"}
                        errorMessage={errors.CreditCard}
                        required
                        setCardComplete={setCardComplete}
                        updateZipCode={(newZip) => setZipCode(newZip)}
                    />

                    {/** ToS Accept **/}
                    <InputCheckbox
                        name="TermsOfServiceAccept"
                        checked={formData.TermsOfServiceAccept}
                        onChange={handleChange}
                        label={
                            <>
                                I have read and accept the <NavLink
                                to="/TermsOfUse">terms of use</NavLink>
                            </>
                        }
                        required={true}
                    />

                    {/** Marketing Consent **/}
                    <InputCheckbox
                        name="AllowCommercialEmails"
                        checked={formData.AllowCommercialEmails}
                        onChange={handleChange}
                        label="Keep me in the loop on the latest releases and new features"
                        required={false}
                    />

                    {/** Form submit button **/}
                    <ButtonPillUppercase label="Join the family" className="" link=""/>
                </form>
            </div>

            {/** API fetch status progress **/}
            <ProgressPanel
                isOpen={progressPopUpActive}
                onClose={() => handleCloseProgressPopUp()}
                progressMessage={progressMessage}
                progressSteps={progressSteps}
            />
        </SignUpForm>
    );
};

export default SignUp;
