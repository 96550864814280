import styled from "styled-components";
import { useEffect, useState, useContext } from 'react';
import { IRelatedVideoInfo } from "../../../Models/DTOs/IRelatedVideoInfo";
import AuthContext from "../../../Store/auth-context";
import { GetRelatedVideosInfo } from "../../../Api/Video";
import NavLinkGreyButton from "../Buttons/NavLinkGreyButton";
import NavLinkPinkButton from "../Buttons/NavLinkPinkButton";
import { RoutePaths } from "../../../Constants/RoutePaths";
import { useLocation } from 'react-router-dom';
import { IconArrowRight, IconList, IconSurprise } from "../../../Assets/Icons/Icons";
import {ResponsiveBreakpoints} from "../../../Constants/ResponsiveBreakpoints";

const Container = styled.div`
    display: flex;
    width: 100%;
    gap: calc(16rem/16);
    a {
        font-size: 10pt;
        margin: 0;
        padding: calc(15rem/16) calc(20rem/16);

        div {
            display: none;
        }

        &:first-of-type {
            div {
                display: block;
            }
        }
    }

    @media screen and (min-width: calc(${ ResponsiveBreakpoints.SmallTabletBreakpoint }rem/16)) {
        a {
            &:first-of-type {
                margin: 0 auto 0 0;

                // If both the first and last (only) element.
                // Should only be true for Films that use the
                // lone "Surprise Me" button
                &:last-of-type {
                    margin: 0 0 0 auto;
                }
            }
        }
    }

    @media screen and (min-width: calc(${ ResponsiveBreakpoints.DesktopBreakpoint }em/16)) {
        justify-content: flex-end;
        a {
            padding: calc(16rem/16) calc(20rem/16);
            div {
                display: block;
            }

            &:first-of-type {
                margin: 0;
            }
        }
    }

    @media screen and (min-width: calc(${ ResponsiveBreakpoints.WideScreenBreakpoint }rem/16)) {
        a {


        }
    }
`;

const IconWrapper = styled.div`
    width: calc(12rem/16);
    height: calc(12rem/16);
    margin: 0 calc(4rem/16) 0 0;
    @media screen and (min-width: calc(${ ResponsiveBreakpoints.DesktopBreakpoint }rem/16)) {
        width: calc(18rem/16);
        height: calc(18rem/16);
    }
`;

function VideoRedirects(props: {
    videoId: number,
}) {
    const controller = new AbortController();
    const [redirects, setRedirects] = useState<IRelatedVideoInfo | null>();
    const authCtx = useContext(AuthContext);

    useEffect(() => {
        async function loadData() {
            const result = await GetRelatedVideosInfo(props.videoId, authCtx.UserDetails.CurrentCountryCode, controller);
            console.log(result);
            setRedirects(result);
        }

        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.videoId]);

    if (redirects === null) {
        return null;
    }

    return (
        <Container>
            { redirects?.NextEpisode !== null ?
                (<>
                    <NavLinkPinkButton to={RoutePaths.Watch(redirects?.NextEpisode as number)}>
                        <IconWrapper>
                            <IconArrowRight />
                        </IconWrapper>

                        Next Episode
                    </NavLinkPinkButton>
                </>)
                : null}
            { redirects?.Series !== null ?
                (<>
                    <NavLinkGreyButton to={RoutePaths.SeriesEpisodes(redirects?.Series as number)}>
                        <IconWrapper>
                            <IconList />
                        </IconWrapper>

                        View All Episodes
                    </NavLinkGreyButton>
                </>)
                : null }
            { redirects?.SurpriseMe !== null ?
                (<>
                    <NavLinkGreyButton to={RoutePaths.Watch(redirects?.SurpriseMe as number)}>
                        <IconWrapper>
                            <IconSurprise />
                        </IconWrapper>

                        Surprise  Me
                    </NavLinkGreyButton>
                </>)
                : null }
        </Container>
    )
}

export default VideoRedirects;
